import { applyMiddleware, createStore } from "redux";
import thunk, { ThunkAction, ThunkDispatch } from "redux-thunk";
import { socketMiddleware } from "./middleware/socketMiddleware";
import { WSS_FEED_URL, WSS_ORDERS_URL } from "../utils/burger-api";
import { wsFeedActions, wsOrdersActions } from "./middleware/middlewareActions";
import { rootReducer } from "./reducers";
import { TAppActions } from "./actions";
import { composeWithDevTools } from "@redux-devtools/extension";

const enhancer = composeWithDevTools(
    applyMiddleware(
        thunk,
        socketMiddleware(WSS_FEED_URL, wsFeedActions),
        socketMiddleware(WSS_ORDERS_URL, wsOrdersActions)
    )
);

export const store = createStore(rootReducer, enhancer);

export type TRootState = ReturnType<typeof store.getState>;

export type TAppDispatch = ThunkDispatch<TRootState, unknown, TAppActions>;

export type TAppThunkAction<TReturn = void> = ThunkAction<TReturn, TRootState, unknown, TAppActions>;