import { Middleware, MiddlewareAPI } from "redux";
import { TWSOrdersResponse, TWSStoreActions } from "../types";
import { TAppDispatch, TRootState } from "../store";
import { TAppActions } from "../actions";

export const socketMiddleware = (wsUrl: string, wsActions: TWSStoreActions): Middleware => {
    return (store: MiddlewareAPI<TAppDispatch, TRootState>) => {
        let socket: WebSocket | null = null;
        return next => (action: TAppActions) => {
            const { dispatch, getState } = store;
            const { type } = action;
            const token = getState().token?.split(' ')[1]
            const { wsInit, wsClose, onOpen, onClose, onError, onMessage, needToken } = wsActions;
            if (type === wsInit) {
                socket = new WebSocket(needToken && token ? `${wsUrl}?token=${token}` : wsUrl);
            }
            if (socket) {
                socket.onopen = () => {
                    dispatch({
                        type: onOpen
                    });
                };
                socket.onerror = event => {
                    dispatch({
                        type: onError,
                        payload: event
                    });
                };
                socket.onclose = () => {
                    dispatch({
                        type: onClose
                    });
                    socket = null;
                };
                socket.onmessage = event => {
                    const { data } = event;
                    const parseData: TWSOrdersResponse = JSON.parse(data);
                    dispatch({
                        type: onMessage,
                        payload: parseData
                    })
                }
                if (type === wsClose) {
                    socket.close()
                }
            }
            next(action);
        }
    }
}
