import modalOverlayStyle from './modal-overlay.module.css';
import { FC } from 'react';

interface IModalOverlayProps {
    onClose(): void;
}

const ModalOverlay: FC<IModalOverlayProps> = ({ onClose }) => {

    return (
        <div className={modalOverlayStyle.overlay} onClick={onClose} />
    );
}

export default ModalOverlay;