import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "../services/hooks";
import { FeedDetails } from "../components/feed-details/feed-details";
import pageStyle from './page.module.css';
import { WS_FEED_CLOSE, WS_FEED_START } from "../services/actions/wsfeed";

const PageFeed: FC = () => {

    const dispatch = useDispatch();

    const { wsConnected, error, response } = useSelector(store => store.wsFeed);

    useEffect(() => {
        dispatch({
            type: WS_FEED_START
        });
        return () => {
            dispatch({
                type: WS_FEED_CLOSE
            })
        }
    }, [dispatch]);

    const { id } = useParams();

    const order = response?.orders?.find(order => order._id === id);

    return (
        <main className={pageStyle.container}>
            {!wsConnected && <div className="text text_type_main-default">Загрузка...</div>}
            {error && <div className="text text_type_main-default">Возникла ошибка</div>}
            {wsConnected && response && <FeedDetails order={order} />}
        </main>
    );
}

export default PageFeed;
