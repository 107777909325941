import { TAppDispatch, TAppThunkAction, TRootState } from "../store";
import { TPostOrder, postOrderRequest } from "../../utils/burger-api";
import { clearBurger } from "./burger";
import { tokenSet } from "./token";

export const ORDER_REQUEST: 'ORDER_REQUEST' = 'ORDER_REQUEST';
export const ORDER_SUCCESS: 'ORDER_SUCCESS' = 'ORDER_SUCCESS';
export const ORDER_ERROR: 'ORDER_ERROR' = 'ORDER_ERROR';
export const ORDER_CLOSE_MODAL: 'ORDER_CLOSE_MODAL' = 'ORDER_CLOSE_MODAL';

export interface IOrderRequestAction {
    readonly type: typeof ORDER_REQUEST;
}

export interface IOrderSuccessAction {
    readonly type: typeof ORDER_SUCCESS;
    readonly data: TPostOrder;
}

export interface IOrderErrorAction {
    readonly type: typeof ORDER_ERROR;
}

export const postOrder = (ingredients: string[]): TAppThunkAction => (dispatch: TAppDispatch, getState: () => TRootState) => {

    const token = getState().token;

    dispatch({
        type: ORDER_REQUEST
    });
    return postOrderRequest(ingredients, token).then(({ response, newToken }) => {
        dispatch({
            type: ORDER_SUCCESS,
            data: response
        });
        dispatch(tokenSet(newToken));
        dispatch(clearBurger());
    }).catch(() => {
        dispatch({
            type: ORDER_ERROR
        });
    })
};

export interface IOrderCloseModalAction {
    readonly type: typeof ORDER_CLOSE_MODAL;
}

export const closeOrderModal = (): IOrderCloseModalAction => {
    return {
        type: ORDER_CLOSE_MODAL
    };
};

export type TOrderActions = IOrderRequestAction
    | IOrderSuccessAction
    | IOrderErrorAction
    | IOrderCloseModalAction;


