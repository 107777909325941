import { ConstructorElement } from "@ya.praktikum/react-developer-burger-ui-components";
import ingredientOutStyle from './ingredient-out.module.css';
import { IIngredient } from "../../utils/data";
import { FC } from "react";

interface IIngredientOutProps {
    type: 'top' | 'bottom';
    ingredient: IIngredient;
}

const IngredientOut: FC<IIngredientOutProps> = ({ type, ingredient }) => {

    const text = type === "top" ? ingredient.name + " (верх)" : ingredient.name + " (низ)";

    return (
        <li className={ingredientOutStyle.element}>
            <ConstructorElement type={type} isLocked={true} text={text} price={ingredient.price} thumbnail={ingredient.image} />
        </li>
    );
};

export default IngredientOut;

