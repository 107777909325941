import { TPostOrder } from "../../utils/burger-api";
import { ORDER_CLOSE_MODAL, ORDER_ERROR, ORDER_REQUEST, ORDER_SUCCESS, TOrderActions } from "../actions/order";

export interface IOrderStore {
    data: TPostOrder | null;
    isLoading: boolean;
    hasError: boolean;
    visibleModal: boolean;
}

const initialStore: IOrderStore = {
    data: null,
    isLoading: false,
    hasError: false,
    visibleModal: false
};

export const orderReducer = (store: IOrderStore = initialStore, action: TOrderActions): IOrderStore => {
    switch (action.type) {
        case ORDER_REQUEST:
            return {
                ...store,
                isLoading: true,
                visibleModal: true
            };
        case ORDER_SUCCESS:
            return {
                ...store,
                data: action.data,
                isLoading: false,
                hasError: false
            };
        case ORDER_ERROR:
            return {
                ...store,
                isLoading: false,
                hasError: true
            };
        case ORDER_CLOSE_MODAL:
            return {
                ...store,
                visibleModal: false
            };
        default:
            return store;
    }
}