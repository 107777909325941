export const TOKEN_SET: 'TOKEN_SET' = 'TOKEN_SET';
export const TOKEN_CLEAR: 'TOKEN_CLEAR' = 'TOKEN_CLEAR';

export interface ITokenSetAction {
    readonly type: typeof TOKEN_SET;
    readonly token: string;
}

export interface ITokenClearAction {
    readonly type: typeof TOKEN_CLEAR;
}

export const tokenSet = (token: string): ITokenSetAction => {
    return {
        type: TOKEN_SET,
        token
    };
};

export const tokenClear = (): ITokenClearAction => {
    return {
        type: TOKEN_CLEAR
    };
};

export type TTokenActions = ITokenSetAction | ITokenClearAction;

