import { Button, EmailInput } from '@ya.praktikum/react-developer-burger-ui-components';
import forgotPasswordStyle from './forgot-password.module.css';
import { Link, useNavigate } from 'react-router-dom';
import Modal from '../components/modal/modal';
import { FC, FormEvent, useState } from 'react';
import { forgotPasswordRequest } from '../utils/burger-api';

export const ForgotPassword: FC = () => {

    const [email, setEmail] = useState('');

    const [hasError, setHasError] = useState(false);

    const navigate = useNavigate();

    const formSubmit = (evt: FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        forgotPasswordRequest(email).then(() => {
            navigate("/reset-password", { replace: true, state: { fromForgot: true } });
        }).catch(() => {
            setHasError(true);
        });
    };

    const closeModal = () => {
        setHasError(false);
    };

    return (
        <form onSubmit={formSubmit}>
            <div className={forgotPasswordStyle.container}>
                <p className={forgotPasswordStyle.title + " text text_type_main-medium"}>Восстановление пароля</p>
                <div className={forgotPasswordStyle.email}>
                    <EmailInput
                        name="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </div>
                <div className={forgotPasswordStyle.button}>
                    <Button
                        type="primary"
                        size="medium"
                        htmlType="submit"
                    >
                        Восстановить
                    </Button>
                </div>
                <p className={forgotPasswordStyle.remember + " text text_type_main-default"}>
                    Вспомнили пароль? <Link to="/login">Войти</Link>
                </p>
            </div>
            {hasError && <Modal title="Возникла ошибка" closeModal={closeModal} />}
        </form>
    );
};