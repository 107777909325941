import { IUser } from "../../utils/data";
import { TUserActions, USER_CLEAR, USER_SET } from "../actions/user";

export type TUserStore = IUser | null;

export const userReducer = (store: TUserStore = null, action: TUserActions): TUserStore => {
    switch (action.type) {
        case USER_SET:
            return action.user;
        case USER_CLEAR:
            return null;
        default:
            return store;
    }
};
