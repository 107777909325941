import { TAppDispatch, TAppThunkAction } from "../store";
import { getIngredientsRequest } from "../../utils/burger-api";
import { TIngredients } from "../../utils/data";

export const INGREDIENTS_REQUEST: 'INGREDIENTS_REQUEST' = 'INGREDIENTS_REQUEST';
export const INGREDIENTS_SUCCESS: 'INGREDIENTS_SUCCESS' = 'INGREDIENTS_SUCCESS';
export const INGREDIENTS_ERROR: 'INGREDIENTS_ERROR' = 'INGREDIENTS_ERROR';

export interface IIngredientsRequestAction {
    readonly type: typeof INGREDIENTS_REQUEST;
}

export interface IIngredientsSuccessAction {
    readonly type: typeof INGREDIENTS_SUCCESS;
    readonly data: TIngredients;
}

export interface IIngredientsErrorAction {
    readonly type: typeof INGREDIENTS_ERROR;
}


export const getIngredients = (): TAppThunkAction => (dispatch: TAppDispatch) => {
    dispatch({
        type: INGREDIENTS_REQUEST
    });
    return getIngredientsRequest().then(res => {
        dispatch({
            type: INGREDIENTS_SUCCESS,
            data: res.data
        });
    }).catch(() => {
        dispatch({
            type: INGREDIENTS_ERROR
        });
    });
}

export type TIngredientsActions = IIngredientsRequestAction
    | IIngredientsSuccessAction
    | IIngredientsErrorAction;
