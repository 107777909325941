import { useSelector } from '../../services/hooks';
import orderDetailsStyle from './order-details.module.css';
import imageOk from "../../images/ok.png";
import { FC } from 'react';

const OrderDetails: FC = () => {

    const numOrder = useSelector(store => store.order.data?.order.number)

    return (
        <div className={orderDetailsStyle.container}>
            <p className={orderDetailsStyle.order + " text text_type_digits-large"} data-testid="order-details-number">{numOrder}</p>
            <p className={orderDetailsStyle.text1 + " text text_type_main-medium"} >идентификатор заказа</p>
            <img className={orderDetailsStyle.image} src={imageOk} alt="ok" />
            <p className={orderDetailsStyle.text2 + " text text_type_main-default"}>Ваш заказ начали готовить</p>
            <p className={orderDetailsStyle.text3 + " text_type_main-default text_color_inactive"}>Дождитесь готовности на орбитальной станции</p>
        </div>
    );
}

export default OrderDetails;