import ingredientDetailsStyle from './ingredient-details.module.css';
import { FC } from 'react';
import { IIngredient } from '../../utils/data';

interface IIngredientDetailsProps {
    ingredient?: IIngredient;
}

const IngredientDetails: FC<IIngredientDetailsProps> = ({ ingredient }) => {

    if (ingredient) {
        return (
            <div className={ingredientDetailsStyle.container}>
                <img className={ingredientDetailsStyle.image} src={ingredient.image_large} alt={ingredient.name} />
                <p className={ingredientDetailsStyle.name + " text text_type_main-medium"}>{ingredient.name}</p>
                <div className={ingredientDetailsStyle.indicators}>
                    <div className={ingredientDetailsStyle.indicator}>
                        <div className="text text_type_main-default text_color_inactive">Калории,ккал</div>
                        <div className="text text_type_main-default text_color_inactive">{ingredient.calories}</div>
                    </div>
                    <div className={ingredientDetailsStyle.indicator}>
                        <div className="text text_type_main-default text_color_inactive">Белки, г</div>
                        <div className="text text_type_main-default text_color_inactive">{ingredient.proteins}</div>
                    </div>
                    <div className={ingredientDetailsStyle.indicator}>
                        <div className="text text_type_main-default text_color_inactive">Жиры, г</div>
                        <div className="text text_type_main-default text_color_inactive">{ingredient.fat}</div>
                    </div>
                    <div className={ingredientDetailsStyle.indicator}>
                        <div className="text text_type_main-default text_color_inactive">Углеводы, г</div>
                        <div className="text text_type_main-default text_color_inactive">{ingredient.carbohydrates}</div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className={ingredientDetailsStyle.container + " text text_type_main-medium"}>
                Страница не найдена. Ошибка 404.
            </div>
        );
    }
};

export default IngredientDetails;