import { FC, ReactNode, useEffect, useState } from "react";
import { useSelector, useDispatch } from "../../services/hooks";
import { getUserRequest } from "../../utils/burger-api";
import { userSet } from "../../services/actions/user";
import { tokenSet } from "../../services/actions/token";
import { Navigate, useLocation } from "react-router-dom";
import protectedRouteStyle from './protected-route.module.css';


interface IProtectedRouteProps {
    unauthOnly?: boolean;
    children?: ReactNode;
}

export const ProtectedRoute: FC<IProtectedRouteProps> = ({ children, unauthOnly }) => {

    const [isLoading, setLoading] = useState(true);

    const user = useSelector(store => store.user);

    const token = useSelector(store => store.token);

    const location = useLocation();

    const dispatch = useDispatch();

    useEffect(() => {
        if (!user) {
            getUserRequest(token).then(({ response, newToken }) => {
                dispatch(userSet(response.user));
                dispatch(tokenSet(newToken));
            }).catch(() => {
            }).finally(() => {
                setLoading(false);
            })
        } else {
            setLoading(false);
        }
    }, [dispatch, token, user]);

    if (isLoading) {
        return (
            <div className={protectedRouteStyle.container + " text text_type_main-default p-5"}>
                Загрузка...
            </div>
        );
    }

    if (unauthOnly && !user) {
        return (
            <>
                {children}
            </>
        );
    }

    if (unauthOnly && user) {
        return (
            <Navigate to="/" />
        );
    }

    if (!user) {
        return (
            <Navigate to="/login" state={{ from: location }} />
        );
    }

    return (
        <>
            {children}
        </>
    );
};
