import { IUser } from "../../utils/data";

export const USER_SET: 'USER_SET' = 'USER_SET';
export const USER_CLEAR: 'USER_CLEAR' = 'USER_CLEAR';

export interface IUserSetAction {
    readonly type: typeof USER_SET;
    readonly user: IUser;
}

export const userSet = (user: IUser): IUserSetAction => {
    return {
        type: USER_SET,
        user
    };
};

export interface IUserClearAction {
    readonly type: typeof USER_CLEAR;
}

export const userClear = (): IUserClearAction => {
    return {
        type: USER_CLEAR
    };
};

export type TUserActions = IUserSetAction | IUserClearAction;

