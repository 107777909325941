import { FC, useMemo } from "react";
import { useSelector } from "../../services/hooks";
import feedDetailsStyle from './feed-details.module.css';
import IngredientImage from "../ingredient-image/ingredient-image";
import { CurrencyIcon, FormattedDate } from "@ya.praktikum/react-developer-burger-ui-components";
import { IWSOrder } from "../../utils/data";

export const statusDefine: {
    done: string;
    created: string;
    pending: string;
} = {
    done: 'Выполнен',
    created: 'Создан',
    pending: 'Готовится'
};

interface IFeedDetailsProps {
    order?: IWSOrder;
}

export const FeedDetails: FC<IFeedDetailsProps> = ({ order }) => {

    const mapping = useSelector(store => store.ingredients.mapping);

    const compound: {
        [id: string]: number;
    } | undefined = useMemo(() =>
        order?.ingredients.reduce((acc: { [id: string]: number }, id) => {
            if (id in acc) {
                acc[id] = acc[id] + 1;
            } else {
                acc[id] = 1;
            }
            return acc;
        }, {}), [order]);

    if (order && compound) {
        return (
            <div className={feedDetailsStyle.detail}>
                <div className={feedDetailsStyle.number + " text text_type_main-medium"}>#{order.number}</div>
                <div className={feedDetailsStyle.name + " text text_type_main-default"}>{order.name}</div>
                <div className={feedDetailsStyle.status + " text text_type_main-default"}>{statusDefine[order.status]}</div>
                <div className={feedDetailsStyle.compound_title + " text text_type_main-medium"}>Состав:</div>
                <ul className={feedDetailsStyle.compound_container}>
                    {Object.keys(compound).map(id =>
                        <li className={feedDetailsStyle.compound_item} key={id}>
                            <IngredientImage image={mapping[id].image} marginRight="16px" />
                            <div className={feedDetailsStyle.compound_name + " text text_type_main-default"}>{mapping[id].name}</div>
                            <div className={feedDetailsStyle.compound_price}>
                                <div className={"text text_type_main-default mr-2"}>
                                    {compound[id] + ' x ' + mapping[id].price}
                                </div>
                                <CurrencyIcon type="primary" />
                            </div>
                        </li>
                    )}
                </ul>
                <div className={feedDetailsStyle.date_total + " text text_type_main-default"}>
                    <div className={feedDetailsStyle.date + " text_color_inactive"} >
                        <FormattedDate date={new Date(order.createdAt)} />
                    </div>
                    <div className={feedDetailsStyle.price_value} >
                        <div className="mr-2">
                            {order.ingredients.reduce((acc: number, id) => acc + mapping[id].price, 0)}
                        </div>
                        <CurrencyIcon type="primary" />
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="text text_type_main-medium">
                Страница не найдена. Ошибка 404.
            </div>
        );
    }
}
