import { FC, useEffect } from "react";
import { Route, Routes, Outlet, useLocation, Location } from "react-router-dom";
import { NotFound404 } from "../../pages/not-found";
import AppHeader from "../app-header/app-header";
import Main from "../main/main";
import { useDispatch } from "../../services/hooks";
import { getIngredients } from "../../services/actions/ingredients";
import { PageIngredient } from "../../pages/page-ingredient";
import { Login } from "../../pages/login";
import { Register } from "../../pages/register";
import { ForgotPassword } from "../../pages/forgot-password";
import { ResetPassword } from "../../pages/reset-password";
import { Profile } from "../../pages/profile";
import { ProtectedRoute } from "../protected-route/protected-route";
import { ProfileForm } from "../../pages/profile-form";
import PageFeed from "../../pages/page-feed";
import HistoryOrdersList from "../history-orders-list/history-orders-list";
import PageHistoryOrder from "../../pages/page-history-order";
import { ModalIngredient } from "../../pages/modal-ingredient";
import ModalFeed from "../../pages/modal-feed";
import ModalHistoryOrder from "../../pages/modal-history-order";
import FeedList from "../../pages/feed-list";

const App: FC = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getIngredients())
    }, [dispatch])

    const location = useLocation();

    const state = location.state as { backgroundLocation?: Location };

    return (
        <>
            <AppHeader />
            <Routes location={state?.backgroundLocation || location}>
                <Route path="/" element={<Main />} />
                <Route path="/feed" element={<FeedList />} />
                <Route path="/feed/:id" element={<PageFeed />} />
                <Route path="/login" element={
                    <ProtectedRoute unauthOnly={true}>
                        <Login />
                    </ProtectedRoute>} />
                <Route path="/register" element={
                    <ProtectedRoute unauthOnly={true}>
                        <Register />
                    </ProtectedRoute>} />
                <Route path="/forgot-password" element={
                    <ProtectedRoute unauthOnly={true}>
                        <ForgotPassword />
                    </ProtectedRoute>} />
                <Route path="/reset-password" element={
                    <ProtectedRoute unauthOnly={true}>
                        <ResetPassword />
                    </ProtectedRoute>} />
                <Route path="/profile" element={
                    <ProtectedRoute>
                        <Profile />
                    </ProtectedRoute>
                }>
                    <Route index element={
                        <ProtectedRoute>
                            <ProfileForm />
                        </ProtectedRoute>
                    } />
                    <Route path="orders" element={
                        <ProtectedRoute>
                            <HistoryOrdersList />
                        </ProtectedRoute>
                    } />
                    <Route path="orders/:id" element={
                        <ProtectedRoute>
                            <PageHistoryOrder />
                        </ProtectedRoute>
                    } />
                </Route>
                <Route path="/ingredients/:id" element={<PageIngredient />} />
                <Route path="*" element={<NotFound404 />} />
            </Routes>
            {state?.backgroundLocation && (
                <Routes>
                    <Route path="/ingredients/:id" element={<ModalIngredient />} />
                    <Route path="/feed/:id" element={<ModalFeed />} />
                    <Route path="/profile/orders/:id" element={
                        <ProtectedRoute>
                            <ModalHistoryOrder />
                        </ProtectedRoute>
                    } />
                </Routes>
            )}
            <Outlet />
        </>
    );
};

export default App;