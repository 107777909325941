import { TWSOrdersResponse } from "../types";

export const WS_ORDERS_START: 'WS_ORDERS_START' = 'WS_ORDERS_START';
export const WS_ORDERS_SUCCESS: 'WS_ORDERS_SUCCESS' = 'WS_ORDERS_SUCCESS';
export const WS_ORDERS_ERROR: 'WS_ORDERS_ERROR' = 'WS_ORDERS_ERROR';
export const WS_ORDERS_CLOSED: 'WS_ORDERS_CLOSED' = 'WS_ORDERS_CLOSED';
export const WS_ORDERS_CLOSE: 'WS_ORDERS_CLOSE' = 'WS_ORDERS_CLOSE';
export const WS_ORDERS_GET_MESSAGE: 'WS_ORDERS_GET_MESSAGE' = 'WS_ORDERS_GET_MESSAGE';

export interface IWSOrdersStartAction {
    readonly type: typeof WS_ORDERS_START;
}

export interface IWSOrdersSuccessAction {
    readonly type: typeof WS_ORDERS_SUCCESS;
}

export interface IWSOrdersErrorAction {
    readonly type: typeof WS_ORDERS_ERROR;
    readonly payload: Event;
}

export interface IWSOrdersClosedAction {
    readonly type: typeof WS_ORDERS_CLOSED;
}

export interface IWSOrdersCloseAction {
    readonly type: typeof WS_ORDERS_CLOSE;
}

export interface IWSOrdersGetMessageAction {
    readonly type: typeof WS_ORDERS_GET_MESSAGE;
    readonly payload: TWSOrdersResponse
}

export type TWSOrdersActions = IWSOrdersStartAction
    | IWSOrdersSuccessAction
    | IWSOrdersErrorAction
    | IWSOrdersClosedAction
    | IWSOrdersCloseAction
    | IWSOrdersGetMessageAction;
