import Product from '../product/product';
import { ICounters, IIngredient, TIngredients } from '../../utils/data';
import productListStyle from './product-list.module.css';
import { FC, LegacyRef } from 'react';

interface ITypeDefine {
    [name: string]: string;
}

const typeDefine: ITypeDefine = {
    bun: 'Булки',
    sauce: 'Соусы',
    main: 'Начинка'
}

interface IProductListProps {
    data: TIngredients;
    type: string;
    refValue: LegacyRef<HTMLDivElement>;
    openModal(ingredient: IIngredient): void;
    counters: ICounters;
}

const ProductList: FC<IProductListProps> = ({ data, type, refValue, openModal, counters }) => {

    return (
        <div id={type} ref={refValue}>
            <p className={productListStyle.name + " text text_type_main-medium"}>{typeDefine[type]}</p>
            <ul className={productListStyle.product_list}>
                {data.filter(it => it.type === type).map(it => (
                    <Product ingredient={it} key={it._id} openModal={() => { openModal(it) }} counters={counters} />
                ))}
            </ul>
        </div>
    );
}

export default ProductList;