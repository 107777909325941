import { useParams } from "react-router-dom";
import IngredientDetails from "../components/ingredient-details/ingredient-details";
import { useSelector } from "../services/hooks";
import { FC } from "react";

export const PageIngredient: FC = () => {

    const { isLoading, hasError, data } = useSelector(store => store.ingredients);

    const { id } = useParams();

    const ingredient = data.find(it => it._id === id);

    return (
        <main style={{ marginTop: "80px" }}>
            {isLoading && <div className="text text_type_main-medium p-10">Загрузка...</div>}
            {hasError && <div className="text text_type_main-medium p-10">Возникла ошибка</div>}
            {!isLoading && !hasError && data.length && <IngredientDetails ingredient={ingredient} />}
        </main>
    );

}