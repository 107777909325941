import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "../../services/hooks";
import { WS_ORDERS_CLOSE, WS_ORDERS_START } from "../../services/actions/wsorders";
import historyOrdersListStyle from './history-orders-list.module.css';
import HistoryOrder from "../history-order/history-order";

const HistoryOrdersList: FC = () => {

    const dispatch = useDispatch();

    const { wsConnected, error, response } = useSelector(store => store.wsOrders);

    useEffect(() => {
        dispatch({
            type: WS_ORDERS_START
        });
        return () => {
            dispatch({
                type: WS_ORDERS_CLOSE
            })
        };
    }, [dispatch])


    return (
        <>
            {!wsConnected && <div className="text text_type_main-default">Загрузка...</div>}
            {error && <div className="text text_type_main-default">Возникла ошибка</div>}
            {wsConnected && response && response.orders &&
                <ul className={historyOrdersListStyle.container}>
                    {response.orders.slice().reverse().map(order =>
                        <HistoryOrder order={order} key={order._id} />
                    )}
                </ul>
            }
        </>
    );
};

export default HistoryOrdersList;