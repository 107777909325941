import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "../services/hooks";
import { WS_ORDERS_CLOSE, WS_ORDERS_START } from "../services/actions/wsorders";
import { FeedDetails } from "../components/feed-details/feed-details";
import pageStyle from './page.module.css';

const PageHistoryOrder: FC = () => {

    const { wsConnected, error, response } = useSelector(store => store.wsOrders);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: WS_ORDERS_START
        });
        return () => {
            dispatch({
                type: WS_ORDERS_CLOSE
            });
        };
    }, [dispatch]);

    const { id } = useParams();

    const order = response?.orders?.find(order => order._id === id);

    return (
        <div className={pageStyle.container}>
            {!wsConnected && <div className="text text_type_main-default">Загрузка...</div>}
            {error && <div className="text text_type_main-default">Возникла ошибка</div>}
            {wsConnected && response && <FeedDetails order={order} />}
        </div>
    );

};

export default PageHistoryOrder;