import { Button, EmailInput, Input } from '@ya.praktikum/react-developer-burger-ui-components';
import registerStyle from './register.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { FC, FormEvent, useState } from 'react';
import { useDispatch } from '../services/hooks';
import { registerUserRequest } from '../utils/burger-api';
import { userSet } from '../services/actions/user';
import Modal from '../components/modal/modal';
import { tokenSet } from '../services/actions/token';

export const Register: FC = () => {

    const [hidden, setHidden] = useState(true);

    const clickIcon = () => {
        setHidden(!hidden);
    }

    const [name, setName] = useState('');

    const [email, setEmail] = useState('');

    const [password, setPassword] = useState('');

    const [hasError, setHasError] = useState(false);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const formSubmit = (evt: FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        registerUserRequest(name, email, password).then(res => {
            dispatch(userSet(res.user));
            dispatch(tokenSet(res.accessToken));
            localStorage.setItem('refreshToken', res.refreshToken);
            navigate("/", { replace: true });
        }).catch(() => {
            setHasError(true);
        });
    };

    const closeModal = () => {
        setHasError(false);
    };

    return (
        <form onSubmit={formSubmit}>
            <div className={registerStyle.container}>
                <p className={registerStyle.title + " text text_type_main-medium"}>Регистрация</p>
                <div className={registerStyle.name}>
                    <Input
                        type="text"
                        placeholder="Имя"
                        name="name"
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                </div>
                <div className={registerStyle.email}>
                    <EmailInput
                        name="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </div>
                <div className={registerStyle.password}>
                    <Input
                        type={hidden ? "password" : "text"}
                        placeholder="Пароль"
                        name="password"
                        icon={hidden ? "ShowIcon" : "HideIcon"}
                        onIconClick={clickIcon}
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                </div>
                <div className={registerStyle.button}>
                    <Button
                        type="primary"
                        size="medium"
                        htmlType="submit"
                    >
                        Зарегистрироваться
                    </Button>
                </div>
                <p className={registerStyle.already + " text text_type_main-default"}>
                    Уже зарегистрированы? <Link to="/login">Войти</Link>
                </p>
            </div>
            {hasError && <Modal title="Возникла ошибка" closeModal={closeModal} />}
        </form>
    );
};