import { Logo, BurgerIcon, ListIcon, ProfileIcon } from '@ya.praktikum/react-developer-burger-ui-components';
import { NavLink } from 'react-router-dom';
import appHeaderStyle from './app-header.module.css';
import { FC } from 'react';

const AppHeader: FC = () => {

    return (
        <header>
            <nav>
                <ul className={appHeaderStyle.container}>
                    <li>
                        <NavLink to="/" className={appHeaderStyle.item}>
                            {({ isActive }) => (
                                <>
                                    <BurgerIcon type={isActive ? "primary" : "secondary"} />
                                    <span className={"text text_type_main-default ml-2" + (isActive ? "" : " text_color_inactive")}>
                                        Конструктор
                                    </span>
                                </>
                            )}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/feed" className={appHeaderStyle.item}>
                            {({ isActive }) => (
                                <>
                                    <ListIcon type={isActive ? "primary" : "secondary"} />
                                    <span className={"text text_type_main-default ml-2" + (isActive ? "" : " text_color_inactive")}>
                                        Лента заказов
                                    </span>
                                </>
                            )}
                        </NavLink>
                    </li>
                    <li>
                        <span className={appHeaderStyle.item_logo}>
                            <Logo />
                        </span>
                    </li>
                    <li>
                        <NavLink to="/profile" className={appHeaderStyle.item_profile} data-testid="header-profile">
                            {({ isActive }) => (
                                <>
                                    <ProfileIcon type={isActive ? "primary" : "secondary"} />
                                    <span className={"text text_type_main-default ml-2" + (isActive ? "" : " text_color_inactive")}>
                                        Личный кабинет
                                    </span>
                                </>
                            )}
                        </NavLink>
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default AppHeader;