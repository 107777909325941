import { IIngredient, TIngredients } from "../../utils/data";
import { INGREDIENTS_ERROR, INGREDIENTS_REQUEST, INGREDIENTS_SUCCESS, TIngredientsActions } from "../actions/ingredients";


export interface IIngredientsStore {
    mapping: {
        [id: string]: IIngredient;
    };
    data: TIngredients;
    isLoading: boolean;
    hasError: boolean;
}


const initialStore: IIngredientsStore = {
    mapping: {},
    data: [],
    isLoading: false,
    hasError: false
};

export const ingredientsReducer = (store: IIngredientsStore = initialStore, action: TIngredientsActions): IIngredientsStore => {
    switch (action.type) {
        case INGREDIENTS_REQUEST:
            return {
                ...store,
                isLoading: true
            };
        case INGREDIENTS_SUCCESS:
            return {
                ...store,
                data: action.data,
                mapping: action.data.reduce((result: { [id: string]: IIngredient }, ingredient) => {
                    result[ingredient._id] = ingredient;
                    return result;
                }, {}),
                isLoading: false,
                hasError: false
            };
        case INGREDIENTS_ERROR:
            return {
                ...store,
                isLoading: false,
                hasError: true
            };
        default:
            return store

    }
};