import { FC } from "react";
import { IWSOrder } from "../../utils/data";
import historyOrderStyle from './history-order.module.css';
import { statusDefine } from "../feed-details/feed-details";
import { CurrencyIcon, FormattedDate } from "@ya.praktikum/react-developer-burger-ui-components";
import IngredientImage from "../ingredient-image/ingredient-image";
import { useSelector } from "../../services/hooks";
import { Link, useLocation } from "react-router-dom";

interface IHistoryOrderProps {
    order: IWSOrder;
}

const HistoryOrder: FC<IHistoryOrderProps> = ({ order }) => {

    const mapping = useSelector(store => store.ingredients.mapping);

    const lenIngredients = order.ingredients.length;

    const location = useLocation();

    return (
        <li className={historyOrderStyle.container}>
            <Link
                to={"/profile/orders/" + order._id}
                state={{ backgroundLocation: location }}
                className={historyOrderStyle.link}
            >
                <div className={historyOrderStyle.number_date}>
                    <div className="text text_type_main-default">
                        #{order.number}
                    </div>
                    <div className="text text_type_main-default text_color_inactive">
                        <FormattedDate date={new Date(order.createdAt)} />
                    </div>
                </div>
                <div className={historyOrderStyle.name + " text text_type_main-default"}>
                    {order.name}
                </div>
                <div className={historyOrderStyle.status + " text text_type_main-default"}>
                    {statusDefine[order.status]}
                </div>
                <div className={historyOrderStyle.compound}>
                    <div className={historyOrderStyle.images}>
                        {
                            order.ingredients.slice(0, 6).map((id, index) =>
                                <IngredientImage
                                    image={mapping[id].image_mobile}
                                    zIndex={5 - index}
                                    last={index === 5 && lenIngredients > 6 ? "+" + (lenIngredients - 6) : undefined}
                                    marginRight="-20px"
                                    key={index}
                                />
                            )
                        }
                    </div>
                    <div className={historyOrderStyle.price}>
                        <div className="text text_type_digits-default mr-2">
                            {order.ingredients.reduce((acc, id) => acc + mapping[id].price, 0)}
                        </div>
                        <CurrencyIcon type='primary' />
                    </div>
                </div>
            </Link>
        </li>
    );
};

export default HistoryOrder;
