import { WS_FEED_CLOSE, WS_FEED_CLOSED, WS_FEED_ERROR, WS_FEED_GET_MESSAGE, WS_FEED_START, WS_FEED_SUCCESS } from "../actions/wsfeed";
import { WS_ORDERS_CLOSE, WS_ORDERS_CLOSED, WS_ORDERS_ERROR, WS_ORDERS_GET_MESSAGE, WS_ORDERS_START, WS_ORDERS_SUCCESS } from "../actions/wsorders";
import { TWSStoreActions } from "../types";

export const wsFeedActions: TWSStoreActions = {
    wsInit: WS_FEED_START,
    wsClose: WS_FEED_CLOSE,
    onOpen: WS_FEED_SUCCESS,
    onError: WS_FEED_ERROR,
    onClose: WS_FEED_CLOSED,
    onMessage: WS_FEED_GET_MESSAGE,
    needToken: false
}

export const wsOrdersActions: TWSStoreActions = {
    wsInit: WS_ORDERS_START,
    wsClose: WS_ORDERS_CLOSE,
    onOpen: WS_ORDERS_SUCCESS,
    onError: WS_ORDERS_ERROR,
    onClose: WS_ORDERS_CLOSED,
    onMessage: WS_ORDERS_GET_MESSAGE,
    needToken: true
}
