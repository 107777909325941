import { NavLink, Outlet, matchPath, useLocation } from 'react-router-dom';
import profileStyle from './profile.module.css';
import { useDispatch } from '../services/hooks';
import { userClear } from '../services/actions/user';
import { tokenClear } from '../services/actions/token';
import { logoutUserRequest } from '../utils/burger-api';
import { FC, MouseEvent, useState } from 'react';
import Modal from '../components/modal/modal';

export const Profile: FC = () => {

    const [hasError, setHasError] = useState(false);

    const dispatch = useDispatch();

    const logOut = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        logoutUserRequest().then(() => {
            dispatch(userClear());
            dispatch(tokenClear());
            localStorage.removeItem('refreshToken');
        }).catch(() => {
            setHasError(true);
        });
    };

    const closeModal = () => {
        setHasError(false);
    }

    const location = useLocation();

    return (
        <>
            <div className={profileStyle.container}>
                <ul className={profileStyle.menu}>
                    <li className={profileStyle.menu_item}>
                        <NavLink to="/profile" end>
                            {({ isActive }) => (
                                <div className={"text text_type_main-medium" + (isActive ? "" : " text_color_inactive")}>
                                    Профиль
                                </div>
                            )}
                        </NavLink>
                    </li>
                    <li className={profileStyle.menu_item}>
                        <NavLink to="/profile/orders">
                            {({ isActive }) => (
                                <div className={"text text_type_main-medium" + (isActive ? "" : " text_color_inactive")}>
                                    История заказов
                                </div>
                            )}
                        </NavLink>
                    </li>
                    <li className={profileStyle.menu_item}>
                        <NavLink onClick={logOut} to="">
                            <div className={"text text_type_main-medium text_color_inactive"}>
                                Выход
                            </div>
                        </NavLink>
                    </li>
                    <li className={profileStyle.description + " text text_type_main-default"}>
                        {matchPath("/profile", location.pathname) && "В этом разделе вы можете изменить свои персональные данные"}
                        {matchPath({ path: "/profile/orders", end: false }, location.pathname) && "В этом разделе вы можете просмотреть свою историю заказов"}
                    </li>
                </ul>
                <div className={profileStyle.content}>
                    <Outlet />
                </div>
            </div>
            {hasError && <Modal title="Возникла ошибка" closeModal={closeModal} />}
        </>
    );
};