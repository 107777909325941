import { ConstructorElement, DragIcon } from '@ya.praktikum/react-developer-burger-ui-components';
import ingredientStyle from './ingredient.module.css';
import { useDrag, useDrop } from 'react-dnd';
import { IIngredientWithUuid } from '../../utils/data';
import { useDispatch } from '../../services/hooks';
import { changeOrderBurger } from '../../services/actions/burger';
import { FC } from 'react';

interface IIngredientProps {
    ingredient: IIngredientWithUuid;
    remove(): void;
    index: number;
}

const Ingredient: FC<IIngredientProps> = ({ ingredient, remove, index }) => {

    const [, dragRef] = useDrag({
        type: "move",
        item: { indexDrag: index }
    });

    const dispatch = useDispatch();

    const [, dropRef] = useDrop({
        accept: "move",
        drop({ indexDrag }: { indexDrag: number }) {
            if (index !== indexDrag) {
                dispatch(changeOrderBurger(indexDrag, index));
            }
        }
    })

    return (
        <li className={ingredientStyle.element} ref={(node) => dropRef(dragRef(node))}>
            <div className={ingredientStyle.drag_icon}>
                <DragIcon type="primary" />
            </div>
            <ConstructorElement isLocked={false} text={ingredient.name} price={ingredient.price} thumbnail={ingredient.image} handleClose={remove} />
        </li>
    );
};

export default Ingredient;
