import { useDispatch, useSelector } from '../services/hooks';
import { Input, EmailInput, Button } from '@ya.praktikum/react-developer-burger-ui-components';
import profileFormStyle from './profile-form.module.css';
import { setUserRequest } from '../utils/burger-api';
import { userSet } from '../services/actions/user';
import { tokenSet } from '../services/actions/token';
import { FC, FormEvent, useEffect, useState } from 'react';
import Modal from '../components/modal/modal';
import { IUser } from '../utils/data';

export const ProfileForm: FC = () => {

    const [hidden, setHidden] = useState(true);

    const clickIcon = () => {
        setHidden(!hidden);
    }

    const { name, email } = useSelector(store => store.user as IUser);

    const [newName, setNewName] = useState(name);

    const [newEmail, setNewEmail] = useState(email);

    const [password, setPassword] = useState('');

    const [change, setChange] = useState(false);

    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        if (newName !== name || newEmail !== email || password !== '') {
            setChange(true);
        } else {
            setChange(false);
        }
    }, [newName, name, newEmail, email, password])

    const cancelChange = () => {
        setNewName(name);
        setNewEmail(email);
        setPassword('');
    }

    const token = useSelector(store => store.token);

    const dispatch = useDispatch();

    const formSubmit = (evt: FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        setUserRequest(newName, newEmail, password, token).then(({ response, newToken }) => {
            dispatch(userSet(response.user));
            dispatch(tokenSet(newToken));
            setPassword('');
        }).catch(() => {
            setHasError(true);
        });
    };

    const closeModal = () => {
        setHasError(false);
    }

    return (
        <form onSubmit={formSubmit} className={profileFormStyle.container}>
            <div className={profileFormStyle.name}>
                <Input
                    type="text"
                    placeholder="Имя"
                    icon="EditIcon"
                    name="name"
                    value={newName}
                    onChange={e => setNewName(e.target.value)}
                    data-testid="profile-name-input"
                />
            </div>
            <div className={profileFormStyle.email}>
                <EmailInput
                    value={newEmail}
                    name="email"
                    onChange={e => setNewEmail(e.target.value)}
                    data-testid="profile-email-input"
                />
            </div>
            <div className={profileFormStyle.password}>
                <Input
                    type={hidden ? "password" : "text"}
                    placeholder="Пароль"
                    icon={hidden ? "ShowIcon" : "HideIcon"}
                    onIconClick={clickIcon}
                    name="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
            </div>
            {change &&
                <div className={profileFormStyle.button_container}>
                    <Button
                        type="primary"
                        size="medium"
                        htmlType="button"
                        onClick={cancelChange}
                    >
                        Отмена
                    </Button>
                    <Button
                        type="primary"
                        size="medium"
                        htmlType="submit"
                    >
                        Сохранить
                    </Button>
                </div>
            }
            {hasError && <Modal title="Возникла ошибка" closeModal={closeModal} />}
        </form>
    );
}