import mainStyle from './main.module.css';
import BurgerConstructor from '../burger-constructor/burger-constructor';
import BurgerIngredients from '../burger-ingredients/burger-ingredients';
import { useSelector } from '../../services/hooks';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FC } from 'react';

const Main: FC = () => {

    const { isLoading, hasError, data } = useSelector(store => store.ingredients);

    return (
        <main className={mainStyle.container}>
            {isLoading && <div className="text text_type_main-default p-5">Загрузка...</div>}
            {hasError && <div className="text text_type_main-default p-5">Возникла ошибка</div>}
            {!isLoading && !hasError && data.length && (
                <DndProvider backend={HTML5Backend}>
                    <BurgerIngredients />
                    <BurgerConstructor />
                </DndProvider>
            )}
        </main>
    );
};

export default Main;