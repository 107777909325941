import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "../services/hooks";
import { FeedDetails } from "../components/feed-details/feed-details";
import Modal from "../components/modal/modal";
import pageStyle from './page.module.css';

const ModalHistoryOrder: FC = () => {

    const navigate = useNavigate();

    const closeModal = () => {
        navigate(-1);
    };

    const { wsConnected, error, response } = useSelector(store => store.wsOrders);


    const { id } = useParams();

    const order = response?.orders?.find(order => order._id === id);

    return (
        <Modal closeModal={closeModal} title="">
            <div className={pageStyle.modal_container}>
                {!wsConnected && <div className="text text_type_main-default">Загрузка...</div>}
                {error && <div className="text text_type_main-default">Возникла ошибка</div>}
                {wsConnected && response && <FeedDetails order={order} />}
            </div>
        </Modal>
    );

};

export default ModalHistoryOrder;
