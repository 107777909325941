import { FC } from "react";
import IngredientImageStyle from './ingredient-image.module.css';

interface IIngredientImageProps {
    image: string;
    last?: string;
    zIndex?: number;
    marginRight?: string
}

const IngredientImage: FC<IIngredientImageProps> = ({ image, last, zIndex, marginRight }) => {
    const style: { [name: string]: string | number } = {};
    if (zIndex) {
        style['zIndex'] = zIndex;
    }
    if (last) {
        style['opacity'] = 0.6;
    }
    if (marginRight) {
        style['marginRight'] = marginRight
    }
    return (
        <div className={IngredientImageStyle.border} style={style}>
            <div
                className={IngredientImageStyle.image + " text text_type_main-small"}
                style={{
                    backgroundImage: "url('" + image + "')",
                }}
            >
                {last}
            </div>
        </div>
    );
}

export default IngredientImage;