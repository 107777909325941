import { FC, useMemo } from 'react';
import { CurrencyIcon, Button } from '@ya.praktikum/react-developer-burger-ui-components';
import Modal from '../modal/modal';
import totalStyle from './total.module.css';
import OrderDetails from '../order-details/order-details';
import { useSelector, useDispatch } from '../../services/hooks';
import { closeOrderModal, postOrder } from '../../services/actions/order';
import { useLocation, useNavigate } from 'react-router-dom';


const Total: FC = () => {

    const { isLoading, hasError, visibleModal } = useSelector(store => store.order);

    const user = useSelector(store => store.user);

    const { bun, ingredients } = useSelector(store => store.burger);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const location = useLocation();

    const handleOpenModal = () => {
        if (user && bun) {
            const ingredientIds = ingredients.map(it => it._id);
            dispatch(
                postOrder([bun._id, ...ingredientIds, bun._id])
            );
        } else {
            navigate("/login", { state: { from: location } });
        }
    }

    const handleCloseModal = () => {
        dispatch(closeOrderModal());
    }

    const price = useMemo(() => (bun ? bun.price * 2 : 0) + ingredients.reduce((acc, it) => acc + it.price, 0), [bun, ingredients]);

    return (
        <div className={totalStyle.container}>
            <p className={totalStyle.total + " text text_type_digits-medium"}>{price}</p>
            <span className={totalStyle.currency}>
                <CurrencyIcon type="primary" />
            </span>
            <span className={totalStyle.button} >
                <Button
                    type="primary"
                    size="medium"
                    onClick={handleOpenModal}
                    htmlType="button"
                    disabled={!bun}
                    data-testid="order-submit-button"
                >
                    Оформить заказ
                </Button>
            </span>
            {visibleModal && (
                <Modal closeModal={handleCloseModal} title="">
                    {isLoading && <div className="text text_type_main-medium p-10">Обработка заказа...</div>}
                    {!isLoading && !hasError && <OrderDetails />}
                    {hasError && <div className="text text_type_main-medium p-10">Возникла ошибка</div>}
                </Modal>
            )}
        </div>
    );
}

export default Total;