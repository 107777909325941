import {
    TWSFeedActions,
    WS_FEED_CLOSED,
    WS_FEED_ERROR,
    WS_FEED_GET_MESSAGE,
    WS_FEED_SUCCESS
} from "../actions/wsfeed"
import { TWSStore } from "../types"

const initialStore: TWSStore = {
    wsConnected: false,
    error: null,
    response: null
}

export const wsFeedReducer = (store: TWSStore = initialStore, action: TWSFeedActions): TWSStore => {
    switch (action.type) {
        case WS_FEED_SUCCESS:
            return {
                ...store,
                error: null,
                wsConnected: true
            };
        case WS_FEED_ERROR:
            return {
                ...store,
                error: action.payload,
                wsConnected: false
            };
        case WS_FEED_CLOSED:
            return {
                ...store,
                error: null,
                wsConnected: false
            };
        case WS_FEED_GET_MESSAGE:
            return {
                ...store,
                response: action.payload
            };
        default:
            return store;
    }
}