import { IIngredientWithUuid, TIngredientsWithUuid } from "../../utils/data";
import { BURGER_ADD, BURGER_CHANGE_ORDER, BURGER_CLEAR, BURGER_REMOVE, BURGER_SET, TBurgerActions } from "../actions/burger";

export interface IBurgerStore {
    bun: IIngredientWithUuid | null | undefined;
    ingredients: TIngredientsWithUuid;
}

const initialStore: IBurgerStore = {
    bun: null,
    ingredients: []
}

export const burgerReducer = (store: IBurgerStore = initialStore, action: TBurgerActions): IBurgerStore => {
    switch (action.type) {
        case BURGER_SET: {
            const bun = action.ingredients.find(it => it.type === 'bun');
            const ingredients = action.ingredients.filter(it => it.type !== 'bun');
            return {
                ...store,
                bun,
                ingredients
            };
        }
        case BURGER_ADD: {
            if (action.item.type === 'bun') {
                if (!(store.bun && store.bun._id === action.item._id)) {
                    return {
                        ...store,
                        bun: action.item
                    };
                } else {
                    return store;
                }
            } else {
                return {
                    ...store,
                    ingredients: [...store.ingredients, action.item]
                };
            }
        }
        case BURGER_REMOVE: {
            return {
                ...store,
                ingredients: store.ingredients.filter(it => it.uuid !== action.uuid)
            };
        }
        case BURGER_CHANGE_ORDER: {
            const ingredients = store.ingredients.map((it, ind, all) => {
                if (action.idx > action.newIdx) {
                    if (ind < action.newIdx) {
                        return it;
                    } else if (ind === action.newIdx) {
                        return all[action.idx];
                    } else if (ind <= action.idx) {
                        return all[ind - 1];
                    } else {
                        return it;
                    }
                } else {
                    if (ind < action.idx) {
                        return it;
                    } else if (ind < action.newIdx) {
                        return all[ind + 1];
                    } else if (ind === action.newIdx) {
                        return all[action.idx];
                    } else {
                        return it;
                    }
                }
            })
            return {
                ...store,
                ingredients
            };
        }
        case BURGER_CLEAR: {
            return {
                ...store,
                bun: null,
                ingredients: []
            }
        }
        default:
            return store
    }
};


