import { Button, Input } from '@ya.praktikum/react-developer-burger-ui-components';
import resetPasswordStyle from './reset-password.module.css';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Modal from '../components/modal/modal';
import { FC, FormEvent, useState } from 'react';
import { resetPasswordRequest } from '../utils/burger-api';

export const ResetPassword: FC = () => {

    const [password, setPassword] = useState('');

    const [code, setCode] = useState('');

    const [hasError, setHasError] = useState(false);

    const navigate = useNavigate();

    const location = useLocation();

    const formSubmit = (evt: FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        resetPasswordRequest(password, code).then(() => {
            navigate("/login", { replace: true });
        }).catch(() => {
            setHasError(true);
        });
    };

    const closeModal = () => {
        setHasError(false);
    }

    const [hidden, setHidden] = useState(true);

    const clickIcon = () => {
        setHidden(!hidden);
    }

    if (location.state?.fromForgot) {
        return (
            <form onSubmit={formSubmit}>
                <div className={resetPasswordStyle.container}>
                    <p className={resetPasswordStyle.title + " text text_type_main-medium"}>Восстановление пароля</p>
                    <div className={resetPasswordStyle.password}>
                        <Input
                            type={hidden ? "password" : "text"}
                            placeholder="Введите новый пароль"
                            icon={hidden ? "ShowIcon" : "HideIcon"}
                            name="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            onIconClick={clickIcon}
                        />
                    </div>
                    <div className={resetPasswordStyle.code}>
                        <Input
                            type="text"
                            placeholder="Введите код из письма"
                            name="code"
                            value={code}
                            onChange={e => setCode(e.target.value)}
                        />
                    </div>
                    <div className={resetPasswordStyle.button}>
                        <Button
                            type="primary"
                            size="medium"
                            htmlType="submit"
                        >
                            Сохранить
                        </Button>
                    </div>
                    <p className={resetPasswordStyle.remember + " text text_type_main-default"}>
                        Вспомнили пароль? <Link to="/login">Войти</Link>
                    </p>
                </div>
                {hasError && <Modal title='Возникла ошибка' closeModal={closeModal} />}
            </form>
        );
    } else {
        return <Navigate to="/forgot-password" replace />;
    }
}