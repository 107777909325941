import { combineReducers } from "redux";
import { ingredientsReducer } from "./ingredients";
import { burgerReducer } from "./burger";
import { orderReducer } from "./order";
import { userReducer } from "./user";
import { tokenReducer } from "./token";
import { wsFeedReducer } from "./wsfeed";
import { wsOrdersReducer } from "./wsorders";

export const rootReducer = combineReducers({
    burger: burgerReducer,
    ingredients: ingredientsReducer,
    order: orderReducer,
    user: userReducer,
    token: tokenReducer,
    wsFeed: wsFeedReducer,
    wsOrders: wsOrdersReducer
});
