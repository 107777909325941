import { useDispatch, useSelector } from '../../services/hooks';
import burgerConstructorStyle from './burger-constructor.module.css';
import Total from '../total/total';
import { addIngredient, removeIngredient } from '../../services/actions/burger';
import { useDrop } from 'react-dnd';
import Ingredient from '../ingredient/ingredient';
import IngredientOut from '../ingredient-out/ingredient-out';
import { FC } from 'react';
import { IIngredient } from '../../utils/data';


const BurgerConstructor: FC = () => {

    const dispatch = useDispatch();

    const { bun, ingredients } = useSelector(store => store.burger);

    const [, dropTarget] = useDrop({
        accept: "ingredient",
        drop(item: IIngredient) {
            dispatch(addIngredient(item));
        }
    })

    return (
        <section className={burgerConstructorStyle.section}>
            <ul className={burgerConstructorStyle.container} ref={dropTarget} data-testid="burger-constructor">
                {bun && (<IngredientOut
                    type="top"
                    ingredient={bun}
                    key={bun._id + "up"}
                />)}
                {ingredients.map(
                    (it, index) => (<Ingredient
                        ingredient={it}
                        key={it.uuid}
                        remove={() => dispatch(removeIngredient(it))}
                        index={index}
                    />)
                )}
                {bun && (<IngredientOut
                    type="bottom"
                    ingredient={bun}
                    key={bun._id + "down"}
                />)}
            </ul>
            <Total />
        </section>
    );
}

export default BurgerConstructor;