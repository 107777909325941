import { IUser, TIngredients, TOrder } from "./data";

export const API_URL = "https://norma.nomoreparties.space/api";
export const WSS_FEED_URL = "wss://norma.nomoreparties.space/orders/all";
export const WSS_ORDERS_URL = "wss://norma.nomoreparties.space/orders";

export function checkResponse<T>(res: Response): Promise<T> {
    return res.ok ? res.json() : res.json().then((err) => Promise.reject(err))
}

export async function request<T>(endpoint: string, options?: RequestInit) {
    return fetch(`${API_URL}/${endpoint}`, options).then(checkResponse<T>);
}

export type TResponseBody<TDataKey extends string = '', TDataType = {}> = {
    success: boolean;
} & {
        [key in TDataKey]: TDataType;
    };

export async function getIngredientsRequest() {
    return request<TResponseBody<'data', TIngredients>>('ingredients');
}

export async function forgotPasswordRequest(email: string) {
    return request<TResponseBody<'message', string>>('password-reset', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({
            email
        })
    });
};

export async function resetPasswordRequest(password: string, code: string) {
    return request<TResponseBody<'message', string>>('password-reset/reset', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({
            password,
            token: code
        })
    });
};

type TGetNewToken = TResponseBody<'accessToken', string> & TResponseBody<'refreshToken', string>;

type TLoginUser = TGetNewToken & TResponseBody<'user', IUser>;

export async function registerUserRequest(name: string, email: string, password: string) {
    return request<TLoginUser>('auth/register', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({
            name,
            email,
            password
        })
    });
};



export async function loginUserRequest(email: string, password: string) {
    return request<TLoginUser>('auth/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({
            email,
            password
        })
    });
};



async function getNewToken() {

    const refreshToken = localStorage.getItem('refreshToken');

    if (refreshToken) {
        return request<TGetNewToken>('auth/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                token: refreshToken
            })
        }).then(res => {
            localStorage.setItem('refreshToken', res.refreshToken);
            return res.accessToken;
        })
    } else {
        return Promise.reject(new Error('Not found refresh token'))
    }
};

interface TTupleResponse<T> {
    response: T;
    newToken: string;
}

export async function requestWithToken<T>(
    endpoint: string,
    options: RequestInit,
    accessToken: string | null
): Promise<TTupleResponse<T>> {
    if (accessToken) {
        return request<T>(endpoint, {
            ...options,
            headers: {
                ...options.headers,
                'Authorization': accessToken
            }
        }).then(
            res => ({ response: res, newToken: accessToken })
        ).catch(err => {
            if (err?.message === 'jwt expired') {
                return getNewToken().then(
                    newToken => requestWithToken<T>(endpoint, options, newToken)
                ).then(res => Promise.resolve(res));
            } else {
                return Promise.reject(err);
            }
        })
    } else {
        return getNewToken().then(newToken => requestWithToken<T>(endpoint, options, newToken));
    }
};

export async function getUserRequest(accessToken: string | null) {
    return requestWithToken<TResponseBody<'user', IUser>>('auth/user', {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        }
    }, accessToken);
};

export async function setUserRequest(name: string, email: string, password: string, accessToken: string | null) {
    return requestWithToken<TResponseBody<'user', IUser>>('auth/user', {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({
            name,
            email,
            password
        })
    }, accessToken);
};

export type TPostOrder = TResponseBody<'order', TOrder> & TResponseBody<'name', string>;

export async function postOrderRequest(ingredients: string[], accessToken: string | null) {
    return requestWithToken<TPostOrder>('orders', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({
            ingredients
        })
    }, accessToken);
}

export async function logoutUserRequest() {

    const refreshToken = localStorage.getItem('refreshToken');

    if (refreshToken) {
        return request<TResponseBody<'message', string>>('auth/logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                token: refreshToken
            })
        });
    } else {
        return Promise.reject(new Error('Not found refresh token'));
    }
}
