import { TWSOrdersResponse } from "../types";

export const WS_FEED_START: 'WS_FEED_START' = 'WS_FEED_START';
export const WS_FEED_SUCCESS: 'WS_FEED_SUCCESS' = 'WS_FEED_SUCCESS';
export const WS_FEED_ERROR: 'WS_FEED_ERROR' = 'WS_FEED_ERROR';
export const WS_FEED_CLOSED: 'WS_FEED_CLOSED' = 'WS_FEED_CLOSED';
export const WS_FEED_CLOSE: 'WS_FEED_CLOSE' = 'WS_FEED_CLOSE';
export const WS_FEED_GET_MESSAGE: 'WS_FEED_GET_MESSAGE' = 'WS_FEED_GET_MESSAGE';

export interface IWSFeedStartAction {
    readonly type: typeof WS_FEED_START;
}

export interface IWSFeedSuccessAction {
    readonly type: typeof WS_FEED_SUCCESS;
}

export interface IWSFeedErrorAction {
    readonly type: typeof WS_FEED_ERROR;
    readonly payload: Event;
}

export interface IWSFeedClosedAction {
    readonly type: typeof WS_FEED_CLOSED;
}

export interface IWSFeedCloseAction {
    readonly type: typeof WS_FEED_CLOSE;
}

export interface IWSFeedGetMessageAction {
    readonly type: typeof WS_FEED_GET_MESSAGE;
    readonly payload: TWSOrdersResponse
}

export type TWSFeedActions = IWSFeedStartAction
    | IWSFeedSuccessAction
    | IWSFeedErrorAction
    | IWSFeedClosedAction
    | IWSFeedCloseAction
    | IWSFeedGetMessageAction;
