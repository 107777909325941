import { useNavigate, useParams } from "react-router-dom";
import Modal from "../components/modal/modal";
import IngredientDetails from "../components/ingredient-details/ingredient-details";
import { useSelector } from "../services/hooks";
import { FC } from "react";

export const ModalIngredient: FC = () => {

    const navigate = useNavigate();

    const closeModal = () => {
        navigate(-1);
    }

    const { isLoading, hasError, data } = useSelector(store => store.ingredients);

    const { id } = useParams();

    const ingredient = data.find(it => it._id === id);

    return (
        <Modal closeModal={closeModal} title="Детали ингредиента">
            {isLoading && <div className="text text_type_main-medium p-10">Загрузка...</div>}
            {hasError && <div className="text text_type_main-medium p-10">Возникла ошибка</div>}
            {!isLoading && !hasError && data.length && <IngredientDetails ingredient={ingredient} />}
        </Modal>
    );
}