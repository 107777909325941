import { FC } from 'react';
import notFound404Style from './not-found.module.css';

export const NotFound404: FC = () => {
    return (
        <div className={notFound404Style.container}>
            <div className={notFound404Style.message + " text_type_main-medium"}>
                Страница не найдена. Ошибка 404.
            </div>
        </div>
    );
};