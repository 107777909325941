import { FC, useEffect } from "react";
import feedListStyle from './feed-list.module.css';
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "../services/hooks";
import { WS_FEED_CLOSE, WS_FEED_START } from "../services/actions/wsfeed";
import Feed from "../components/feed/feed";

const FeedList: FC = () => {

    const dispatch = useDispatch();

    const { wsConnected, error, response } = useSelector(store => store.wsFeed);

    useEffect(() => {
        dispatch({
            type: WS_FEED_START
        });
        return () => {
            dispatch({
                type: WS_FEED_CLOSE
            })
        };
    }, [dispatch]);

    const location = useLocation();

    return (
        <main className={feedListStyle.container}>
            <p className={feedListStyle.title + " text text_type_main-large"}>Лента заказов</p>
            <div className={feedListStyle.feed}>
                {!wsConnected && <div className="text text_type_main-default">Загрузка...</div>}
                {error && <div className="text text_type_main-default">Возникла ошибка</div>}
                {wsConnected && response &&
                    <>
                        <ul className={feedListStyle.list}>
                            {response.orders?.map(order => <Feed order={order} key={order._id} />)}
                        </ul>
                        <div className={feedListStyle.status}>
                            <div className={feedListStyle.ready_work}>
                                <div className={feedListStyle.ready}>
                                    <div
                                        className={feedListStyle.title_ready_work + " text text_type_main-medium"}
                                    >
                                        Готовы:
                                    </div>
                                    <div
                                        className={feedListStyle.num_order + " text text_type_digits-default"}
                                        style={{ color: "#00CCCC" }}
                                    >
                                        {response.orders?.filter(order => order.status === 'done').slice(0, 20).map(order =>
                                            <Link
                                                key={order._id}
                                                to={"/feed/" + order._id}
                                                className={feedListStyle.link}
                                                state={{ backgroundLocation: location }}
                                            >
                                                {order.number}
                                            </Link>
                                        )}
                                    </div>
                                </div>
                                <div className={feedListStyle.work}>
                                    <div
                                        className={feedListStyle.title_ready_work + " text text_type_main-medium"}
                                    >
                                        В работе:
                                    </div>
                                    <div
                                        className={feedListStyle.num_order + " text text_type_digits-default"}
                                    >
                                        {response.orders?.filter(order => order.status !== 'done').slice(0, 20).map(order =>
                                            <Link
                                                key={order._id}
                                                to={"/feed/" + order._id}
                                                className={feedListStyle.link}
                                                state={{ backgroundLocation: location }}
                                            >
                                                {order.number}
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="text text_type_main-medium">Выполнено за все время:</div>
                                <div className={feedListStyle.totalall_value + " text text_type_digits-large"}>
                                    {response.total}
                                </div>
                            </div>
                            <div>
                                <div className={"text text_type_main-medium"}>Выполнено за сегодня:</div>
                                <div className={"text text_type_digits-large"}>{response.totalToday}</div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </main>
    );
}

export default FeedList;
