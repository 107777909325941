import { Tab } from '@ya.praktikum/react-developer-burger-ui-components';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ProductList from '../product-list/product-list';
import burgerIngredientsStyle from './burger-ingredients.module.css';
import { useSelector } from '../../services/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { ICounters, IIngredient } from '../../utils/data';


const BurgerIngredients: FC = () => {


    const navigate = useNavigate();

    const location = useLocation();

    const handleOpenModal = (ingredient: IIngredient) => {
        navigate("/ingredients/" + ingredient._id, { state: { backgroundLocation: location } });
    }

    const data = useSelector(store => store.ingredients.data);

    const [current, setCurrent] = useState('bun')

    const setCurrentTab = (tab: string) => {
        setCurrent(tab);
        const element = document.getElementById(tab)
        if (element) {
            element.scrollIntoView({ behavior: "smooth" })
        }
    }

    const containerRef = useRef<HTMLDivElement>(null);
    const bunRef = useRef<HTMLDivElement>(null);
    const sauceRef = useRef<HTMLDivElement>(null);
    const mainRef = useRef<HTMLDivElement>(null);


    const callBackObserver = useCallback((entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                setCurrent(entry.target.id);
            }
        })
    }, [setCurrent])

    const optionsObserver = useMemo(() => ({
        root: containerRef.current,
        rootMargin: '0px 0px -680px 0px',
        threshold: 0,
    }), [containerRef]);

    useEffect(() => {
        const observer = new IntersectionObserver(callBackObserver, optionsObserver);
        if (bunRef.current)
            observer.observe(bunRef.current);
        if (sauceRef.current)
            observer.observe(sauceRef.current);
        if (mainRef.current)
            observer.observe(mainRef.current);
        return () => {
            observer.disconnect()
        }
    }, [callBackObserver, optionsObserver, containerRef, bunRef, sauceRef, mainRef]);

    const { bun, ingredients } = useSelector(store => store.burger);

    const counters: ICounters = useMemo(() => {
        const counters: ICounters = {};
        if (bun) {
            counters[bun._id] = 2;
        }
        ingredients.forEach(it => {
            if (it._id in counters) {
                counters[it._id]++;
            } else {
                counters[it._id] = 1;
            }
        })
        return counters;
    }, [bun, ingredients]);

    return (
        <>
            <section className={burgerIngredientsStyle.section}>
                <p className={burgerIngredientsStyle.title + " text text_type_main-large"}>Соберите бургер</p>
                <div className={burgerIngredientsStyle.tab_container}>
                    <Tab value='bun' active={current === 'bun'} onClick={setCurrentTab}>
                        Булки
                    </Tab>
                    <Tab value='sauce' active={current === 'sauce'} onClick={setCurrentTab}>
                        Соусы
                    </Tab>
                    <Tab value='main' active={current === 'main'} onClick={setCurrentTab}>
                        Начинки
                    </Tab>
                </div>
                <div className={burgerIngredientsStyle.ingredients} ref={containerRef}>
                    <ProductList data={data} type='bun' refValue={bunRef} openModal={handleOpenModal} counters={counters} />
                    <ProductList data={data} type='sauce' refValue={sauceRef} openModal={handleOpenModal} counters={counters} />
                    <ProductList data={data} type='main' refValue={mainRef} openModal={handleOpenModal} counters={counters} />
                </div>
            </section>
        </>
    );
}

export default BurgerIngredients;