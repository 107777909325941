import { TOKEN_CLEAR, TOKEN_SET, TTokenActions } from "../actions/token";

export type TTokenStore = string | null;

export const tokenReducer = (store: TTokenStore = null, action: TTokenActions): TTokenStore => {
    switch (action.type) {
        case TOKEN_SET:
            return action.token;
        case TOKEN_CLEAR:
            return null;
        default:
            return store;
    }
};
