import { CurrencyIcon, Counter } from '@ya.praktikum/react-developer-burger-ui-components';
import productStyle from './product.module.css';
import { ICounters, IIngredient } from '../../utils/data';
import { useDrag } from 'react-dnd';
import { FC } from 'react';

interface IProductProps {
    ingredient: IIngredient,
    openModal(): void;
    counters: ICounters;
}

const Product: FC<IProductProps> = ({ ingredient, openModal, counters }) => {

    const { image, name, price } = ingredient;

    const [, dragRef] = useDrag({
        type: "ingredient",
        item: ingredient
    });

    return (
        <li className={productStyle.product} onClick={openModal}>
            {counters[ingredient._id] && <Counter count={counters[ingredient._id]} size="default" />}
            <img className={productStyle.image} src={image} alt={name} ref={dragRef} data-testid="ingredients-product" />
            <span className={productStyle.price}>
                <span className="text text_type_digits-default mr-2">{price}</span>
                <CurrencyIcon type="primary" />
            </span>
            <span className={productStyle.name + " text text_type_main-default"}>{name}</span>
        </li>
    );
}


export default Product;