import {
    TWSOrdersActions,
    WS_ORDERS_CLOSED,
    WS_ORDERS_ERROR,
    WS_ORDERS_GET_MESSAGE,
    WS_ORDERS_SUCCESS
} from "../actions/wsorders"
import { TWSStore } from "../types"

const initialStore: TWSStore = {
    wsConnected: false,
    error: null,
    response: null
}

export const wsOrdersReducer = (store: TWSStore = initialStore, action: TWSOrdersActions): TWSStore => {
    switch (action.type) {
        case WS_ORDERS_SUCCESS:
            return {
                ...store,
                error: null,
                wsConnected: true
            };
        case WS_ORDERS_ERROR:
            return {
                ...store,
                error: action.payload,
                wsConnected: false
            };
        case WS_ORDERS_CLOSED:
            return {
                ...store,
                error: null,
                wsConnected: false
            };
        case WS_ORDERS_GET_MESSAGE:
            return {
                ...store,
                response: action.payload
            };
        default:
            return store;
    }
}