import { FC, ReactNode, useCallback, useEffect } from 'react';
import { createPortal } from "react-dom";
import { CloseIcon } from '@ya.praktikum/react-developer-burger-ui-components';
import modalStyle from './modal.module.css';
import ModalOverlay from '../modal-overlay/modal-overlay';

const modalRoot = document.getElementById("react-modals") as Element;

interface IModalProps {
    title: string;
    children?: ReactNode;
    closeModal(): void;
}

const Modal: FC<IModalProps> = ({ title, children, closeModal }) => {

    const handleKeyDown = useCallback((evt: KeyboardEvent) => {
        if (evt.code === 'Escape') {
            closeModal()
        }
    }, [closeModal])

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [handleKeyDown])

    return createPortal(
        (
            <>
                <ModalOverlay onClose={closeModal} />
                <div className={modalStyle.modal}>
                    <div className={modalStyle.container}>
                        <div className={modalStyle.title}>
                            <div className="text text_type_main-large" data-testid="modal-title">{title}</div>
                            <div>
                                <CloseIcon type="primary" onClick={closeModal} />
                            </div>
                        </div>
                    </div>
                    {children}
                </div>
            </>
        ), modalRoot
    );
}

export default Modal;