import { FC } from 'react';
import { CurrencyIcon, FormattedDate } from '@ya.praktikum/react-developer-burger-ui-components';
import feedStyle from './feed.module.css';
import { IWSOrder } from '../../utils/data';
import { useSelector } from '../../services/hooks';
import IngredientImage from '../ingredient-image/ingredient-image';
import { Link, useLocation } from 'react-router-dom';

interface IFeedProps {
    order: IWSOrder;
}

const Feed: FC<IFeedProps> = ({ order }) => {

    const mapping = useSelector(store => store.ingredients.mapping);

    const lenIngredients = order.ingredients.length;

    const location = useLocation()

    return (
        <li className={feedStyle.feed}>
            <Link to={"/feed/" + order._id} state={{ backgroundLocation: location }} className={feedStyle.link}>
                <div className={feedStyle.identifier}>
                    <div className="text text_type_main-default">
                        #{order.number}
                    </div>
                    <div className="text text_type_main-default text_color_inactive">
                        <FormattedDate date={new Date(order.createdAt)} />
                    </div>
                </div>
                <div className={feedStyle.name + " text text_type_main-default"}>
                    {order.name}
                </div>
                <div className={feedStyle.compound}>
                    <div className={feedStyle.images}>
                        {
                            order.ingredients.slice(0, 6).map((id, index) =>
                                <IngredientImage
                                    image={mapping[id].image_mobile}
                                    zIndex={5 - index}
                                    last={index === 5 && lenIngredients > 6 ? "+" + (lenIngredients - 6) : undefined}
                                    marginRight="-20px"
                                    key={index}
                                />
                            )
                        }
                    </div>
                    <div className={feedStyle.price}>
                        <div className="text text_type_digits-default mr-2">
                            {order.ingredients.reduce((acc, id) => acc + mapping[id].price, 0)}
                        </div>
                        <CurrencyIcon type='primary' />
                    </div>
                </div>
            </Link>
        </li>
    );
};

export default Feed;