import {
    IIngredient,
    IIngredientWithUuid,
    TIngredients,
    TIngredientsWithUuid
} from "../../utils/data";

export const BURGER_SET: 'BURGER_SET' = 'BURGER_SET';
export const BURGER_ADD: 'BURGER_ADD' = 'BURGER_ADD';
export const BURGER_REMOVE: 'BURGER_REMOVE' = 'BURGER_REMOVE';
export const BURGER_CHANGE_ORDER: 'BURGER_CHANGE_ORDER' = 'BURGER_CHANGE_ORDER';
export const BURGER_CLEAR: 'BURGER_CLEAR' = 'BURGER_CLEAR';

export interface IBurgerSetAction {
    readonly type: typeof BURGER_SET;
    readonly ingredients: TIngredientsWithUuid;
}

export const setBurger = (ingredients: TIngredients): IBurgerSetAction => {
    return {
        type: BURGER_SET,
        ingredients: ingredients.map(it => ({ ...it, uuid: crypto.randomUUID() }))
    };
}

export interface IBurgerAddAction {
    readonly type: typeof BURGER_ADD;
    readonly item: IIngredientWithUuid;
}


export const addIngredient = (item: IIngredient): IBurgerAddAction => {
    return {
        type: BURGER_ADD,
        item: { ...item, uuid: crypto.randomUUID() }
    }
};

export interface IBurgerRemoveAction {
    readonly type: typeof BURGER_REMOVE;
    readonly uuid: string;
}

export const removeIngredient = (item: IIngredientWithUuid): IBurgerRemoveAction => {
    return {
        type: BURGER_REMOVE,
        uuid: item.uuid
    }
};

export interface IBurgerChangeOrderAction {
    readonly type: typeof BURGER_CHANGE_ORDER;
    readonly idx: number;
    readonly newIdx: number;
}

export const changeOrderBurger = (index: number, newIndex: number): IBurgerChangeOrderAction => {
    return {
        type: BURGER_CHANGE_ORDER,
        idx: index,
        newIdx: newIndex
    };
}

export interface IBurgerClearAction {
    readonly type: typeof BURGER_CLEAR;
}

export const clearBurger = (): IBurgerClearAction => {
    return {
        type: BURGER_CLEAR
    };
};

export type TBurgerActions = IBurgerSetAction
    | IBurgerAddAction
    | IBurgerRemoveAction
    | IBurgerChangeOrderAction
    | IBurgerClearAction;


